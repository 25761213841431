import {
  ADDRESS_NOT_IN_LIST_CASE,
  PaymentCreateFormValues,
} from '@/components/Forms/PaymentCreate/types'
import { onlyDefined } from '@/utils'
import { toastMessage } from '@alma/react-components'
import { useEffect, useMemo } from 'react'
import { DeepPartial } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

export const formSearchParams = [
  'first_name' as const,
  'last_name' as const,
  'email' as const,
  'phone' as const,
  'line1' as const,
  'line2' as const,
  'postal_code' as const,
  'city' as const,
  'country' as const,
  'purchase_amount' as const,
  'merchant_reference' as const,
]

export type FormSearchParams = (typeof formSearchParams)[0]
export type SearchParamValue = Partial<Record<FormSearchParams, string>>

export const ERROR_JSON_PARSING = Symbol('bad_json_parsing')

export const mergeFormValues = (
  baseValues: DeepPartial<PaymentCreateFormValues>,
  values: DeepPartial<PaymentCreateFormValues>
): DeepPartial<PaymentCreateFormValues> => ({
  customer: {
    ...baseValues.customer,
    ...values.customer,
    // If there is a manual address added, remove addressAutocomplete value, otherwise keep it.
    addressAutocomplete: values.customer?.addressManual
      ? ADDRESS_NOT_IN_LIST_CASE
      : baseValues.customer?.addressAutocomplete,
    addressManual: Object.keys(values.customer?.addressManual ?? {}).length
      ? values.customer?.addressManual
      : baseValues.customer?.addressManual,
  },
  order: { ...baseValues.order, ...values.order },
  purchase: { ...baseValues.purchase, ...values.purchase },
  offerFees: { ...baseValues.offerFees, ...values.offerFees },
  phone: { ...baseValues.phone, ...values.phone },
  custom: Object.fromEntries(
    Object.keys({ ...baseValues.custom, ...values.custom }).map((key) => [
      key,
      values.custom?.[key] ? values.custom?.[key] : baseValues.custom?.[key] ?? '',
    ])
  ),
  link: {
    ...baseValues.link,
    ...values.link,
  },
  requirements: { ...baseValues.requirements, ...values.requirements },
  terminal: { ...baseValues.terminal, ...values.terminal },
})

export function fillFormWithSearchParams(
  searchParams: SearchParamValue
): DeepPartial<PaymentCreateFormValues> {
  const addressManual = onlyDefined({
    address: searchParams.line1,
    city: searchParams.city,
    country: searchParams.country,
    zipCode: searchParams.postal_code,
  })
  return {
    customer: onlyDefined({
      name: searchParams.last_name,
      firstName: searchParams.first_name,
      email: searchParams.email,
      addressManual: Object.keys(addressManual).length > 0 ? addressManual : undefined,
      addressComplement: searchParams.line2,
    }),
    order: onlyDefined({
      reference: searchParams.merchant_reference,
    }),
    purchase: onlyDefined({
      formattedAmountInEuros: searchParams.purchase_amount,
    }),
    phone: onlyDefined({
      number: searchParams.phone,
    }),
  }
}

export function useValuesFromSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  const presetValues = useMemo(() => {
    const searchParamsKeys = [...searchParams.keys()]
    if (!searchParamsKeys.length) {
      return null
    }

    const valuesJson = searchParams.get('values')

    const isValidParams =
      searchParamsKeys.some((param) => formSearchParams.includes(param as FormSearchParams)) ||
      valuesJson

    if (!isValidParams) {
      return null
    }

    try {
      const valuesJsonParsed = valuesJson ? JSON.parse(valuesJson) : {}
      const unsafeSearchParamsResults: SearchParamValue = Object.fromEntries(searchParams.entries())

      // fillFormWithSearchParams will take care of the values needed
      const filledSearchParams = fillFormWithSearchParams(unsafeSearchParamsResults)
      const filledFormValues = mergeFormValues(filledSearchParams, valuesJsonParsed)

      return filledFormValues
    } catch (e) {
      return ERROR_JSON_PARSING
    }
  }, [searchParams])

  useEffect(() => {
    if (presetValues === ERROR_JSON_PARSING) {
      toastMessage({
        type: 'error',
        message: intl.formatMessage({
          id: 'home.fill.error',
          defaultMessage: 'The data provided from the URL are not valid',
          description:
            'Error toaster when incorrect data have been passed to the url to pre-fill the form',
        }),
        duration: Infinity,
        closable: true,
      })
      setSearchParams('')
    }
  }, [presetValues, intl, setSearchParams])

  return presetValues
}
