import React, { FunctionComponent } from 'react'
import s from '@/components/Pages/Home/StopCashInBanner/StopCashInBanner.module.css'
import { Button, Icon, IconName } from '@alma/react-components'
import { FormattedMessage } from 'react-intl'
import { config } from '@/config'
import { TrackerProps } from '@/thirdParties/analytics/useTracking'

const StopCashInBanner: FunctionComponent<TrackerProps> = ({ track }) => (
  <div className={s.container}>
    <div className={s.containerTitle}>
      <Icon icon={IconName.info} isFilled />
      <div className={s.title}>
        <FormattedMessage
          id="home.stop.cash.in.banner.title"
          defaultMessage="Your account is not validated, therefore payments are not accepted."
          description="Title of the Card displayed on the Stop Cash In Banner on top of the Home"
        />
      </div>
    </div>
    <div className={s.button}>
      <Button small>
        <a
          href={`${config.DASHBOARD_URL}/onboarding`} /** Open the Company Page */
          target="_blank"
          rel="noreferrer"
          className={s.link}
          onClick={() => track('company_page')}
        >
          <FormattedMessage
            id="home.stop.cash.in.banner.button"
            defaultMessage="Follow the validation of my account"
            description="Text of the Button displayed on the Stop Cash In Banner on top of the Home"
          />
        </a>
      </Button>
    </div>
  </div>
)

export { StopCashInBanner }
